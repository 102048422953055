$(function () {

  var currentSlideIndex = 0;
  var parcels = $('.parcels');
  var imagesContainer = parcels.find('.parcels__container');
  var images = imagesContainer.children('.parcel-box__container');
  var imageContainerSlider = parcels.find('.parcels__container-slider');
  var arrowLeft = parcels.find('.parcels__carousel-arrow-left');
  var arrowRight = parcels.find('.parcels__carousel-arrow-right');
  var indicators = parcels.find('.carousel__indicator');

  // Define the click handlers
  arrowLeft.click(function () {
    var index = getPrevSlideIndex();
    displayImage(index);
    updateIndicators(index);
  });
  arrowRight.click(function () {
    var index = getNextSlideIndex();
    displayImage(index)
    updateIndicators(index);
  });
  indicators.click(function () {
    var index = $(this).index();
    displayImage(index)
    updateIndicators(index);
  });

  $(window).resize(function () {
    displayImage(currentSlideIndex);
  });

  function getNextSlideIndex() {
    if (currentSlideIndex === images.length - 1) {
      return 0;
    }

    return currentSlideIndex + 1;
  }

  function getPrevSlideIndex() {
    if (currentSlideIndex === 0) {
      return images.length - 1;
    }

    return currentSlideIndex - 1;
  }

  // Applies the correct 'left' property to the images container
  // to provide a sliding carousel effect.
  function displayImage(index) {
    var width = imageContainerSlider.outerWidth();
    imagesContainer.css('left', -(width * index));
    currentSlideIndex = index;
  }

  function updateIndicators(index) {
    indicators.removeClass('carousel__indicator--selected');
    $(indicators.get(index)).addClass('carousel__indicator--selected');
  }
});