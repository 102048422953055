
$(function () {

  $(window).resize(setHeader);

  var headerContainer = $(".header__container");
  var headerLogo = headerContainer.find(".header__logo");

  var navButton = headerContainer.find(".header__nav-button");
  var navLinks = headerContainer.children('.header__nav-group').children(".header__nav-link");
  var navLinkWidths = getHeaderWidths(navLinks);

  var moreButton = headerContainer.find(".header__nav-more");
  var moreNavLinks = moreButton.find(".more__nav-link");


  function setHeader() {
    hideAll();

    var localWidth = headerLogo.outerWidth() + navButton.outerWidth();
    var padding = 64;
    var navLinksContainerWidth = headerContainer.outerWidth() - localWidth - padding;

    for (let i = 0; i < navLinkWidths.length; i++) {
      var width = navLinkWidths[i];
      localWidth += width;

      if (localWidth > navLinksContainerWidth) {
        // If required width is more than what we have, we
        // add a "more" button.
        setMore(i);
        break;
      }

      $(navLinks[i]).css("display", "inherit");
    }
  }

  function setMore(indexForRemaining) {
    moreButton.css("display", "inherit");

    for (let i = indexForRemaining; i < moreNavLinks.length; i++) {
      $(moreNavLinks[i]).css("display", "inherit");
    }
  }

  function hideAll() {
    moreButton.css("display", "none");
    navLinks.css("display", "none");
    moreNavLinks.css("display", "none");
  }

  function getHeaderWidths(navLinks) {
    var widths = [];
    for (let i = 0; i < navLinks.length; i++) {
      widths.push($(navLinks[i]).outerWidth());
    }

    return widths;
  }

  setHeader();


  moreButton.click(function () {
    $(".header__nav-more-dropdown").toggleClass("active");
  });
});