$(function () {
	// Back to portfolio fixed bottom bar
	if ($('#banner_main_portfolio').length > 0){
	    $('footer').css("padding-bottom","134px");
	    $(window).scroll(function() {
	        // 0.8 => 80% doc height
            var scrolled = 0.8;
            // HACK FOR OLSHAN PROPERTIES
            if (bootstrap.client_id == 63){
                scrolled = 0;
            }
	       if($(window).scrollTop() + $(window).height() >= $(document).height() * scrolled) {
	       	$('#banner_main_portfolio').removeClass("closing");
	        $('#banner_main_portfolio').addClass('open');
	       }else{
	    	$('#banner_main_portfolio').addClass("closing");
	       }
	    });
	}
});