$(document).ready(function(){

	let 
		is_iframe_loaded = false,
		inlineplayer = $('.virtualtour__inlineplayer'),
		playbutton = inlineplayer.parents('.virtualtour').find('.play-button')

	let init = function(){

		$(window).on('scroll', function(e){
			if (e.currentTarget.scrollY > 200){
				if (!is_iframe_loaded) loadIframe()
			}
		})

		playbutton.on('click', loadIframe)
	}

	let loadIframe = function(){
		inlineplayer.addClass('is-activated').attr('src', inlineplayer.attr('data-src'))
		is_iframe_loaded = true
	}


	if ( inlineplayer.length ) init()

});